import '../css/amenities.css'
import bed from '../images/amenity_logos/bed_logo.png';
import carport from '../images/amenity_logos/carport.png';
import wifi from '../images/amenity_logos/wifi.png';
import tv from '../images/amenity_logos/tv.png';
import beach from '../images/amenity_logos/beach.png';
import pool from '../images/amenity_logos/pool.png';
import elevator from '../images/amenity_logos/elevator.png';
import shuffleboard from '../images/amenity_logos/shuffleboard.png';
import gym from '../images/amenity_logos/gym.png';
import library from '../images/amenity_logos/library.png';
import pooltable from '../images/amenity_logos/pooltable.png';
import hut from '../images/amenity_logos/hut.png';
import laundry from '../images/amenity_logos/laundry.png'
import camera from '../images/amenity_logos/camera.png';
import security from '../images/amenity_logos/security.png';
import ac from '../images/amenity_logos/ac.png';
import linens from '../images/amenity_logos/linen.png';
import chair from '../images/amenity_logos/chair.png';
import fireplace from '../images/amenity_logos/fireplace.png';
import hair_dryer from '../images/amenity_logos/hair_dryer.jpeg';
import coffee from '../images/amenity_logos/coffee.png';
import dishwasher from '../images/amenity_logos/dishwasher.png';
import range from '../images/amenity_logos/range.png';
import fridge from '../images/amenity_logos/refridgerator.png';
import microwave from '../images/amenity_logos/microwave.png';
import dishes from '../images/amenity_logos/dishes.png'

import {Container, Row, Col} from 'react-bootstrap';

function extra_amenities() {
        alert(
            "Pack n play\n" +
            "Highchair\n" +
            "Sound machine\n" +
            "Hair dryers (2)\n" + 
            "Iron + board\n" +
            "Dedicated workspace\n" + 
            "Children’s books + toy basket\n" + 
            "Smoke alarm\n" +
            "Carbon monoxide detector\n" + 
            "Toaster\n" +
            "Blender\n" +
            "Ice Maker\n"
        );
}


function AmenitiesComponent () {
    return (
        <div className="grid-container" id="amenities">
        <br/>
        <br/>
        <h2 style= { {textAlign: `center`}}>Amenities</h2>
        <Container className="amenities">
            <Row>
                <Col>
                    <h4>Bedrooms</h4>
                </Col>
            </Row>
            <Row xs={1} sm={1} md={2}>
                <Col>
                    <Container fluid className="bed">
                        <img src={bed} alt="bedicon" className="symbol"/>
                        <br/>
                        Master Suite with King size bed
                        <br/>
                        Full bath & standup shower
                    </Container>
                </Col>
                <Col>
                    <Container fluid className="bed">
                        <img src={bed} alt="bedicon" className="symbol"/>
                        <br/>
                        Guest Suite with Queen size bed
                        <br/>
                        Full bath & standup shower
                    </Container>
                </Col>
            </Row>
            <Row>
                <br/>
                <br/>
            </Row>
            <Row>
                <Col className="col-amenities">
                <ul>
                    
                    <li className="li-amenities">
                        <img src={wifi} alt="icon" className="li-icon"/>
                        WiFi
                    </li>
                    <li className="li-amenities">
                        <img src={tv} alt="icon" className="li-icon"/>
                        Smart TV and Cable
                    </li>
                    <li className="li-amenities">
                        <img src={ac} alt="icon" className="li-icon"/>
                        Air Conditioning and Heating
                    </li>
                    <li className="li-amenities">
                        <img src={linens} alt="icon" className="li-icon"/>
                        Linens Provided (Including Beach Towels)
                    </li>
                    <li className="li-amenities">
                        <img src={chair} alt="icon" className="li-icon"/>
                        Beach Chairs
                    </li>
                    <li className="li-amenities">
                        <img src={fireplace} alt="icon" className="li-icon"/>
                        Fireplace
                    </li>
                    <li className="li-amenities">
                        <img src={hair_dryer} alt="icon" className="li-icon"/>
                        Hair Dryers (2)
                    </li>
                    <li className="li-amenities">
                        <img src={coffee} alt="icon" className="li-icon"/>
                        Keurig Coffee Maker
                    </li>
                    <li className="li-amenities">
                        <img src={dishwasher} alt="icon" className="li-icon"/>
                        Dishwasher
                    </li>
                    <li className="li-amenities">
                        <img src={fridge} alt="icon" className="li-icon"/>
                        Refrigerator
                    </li>
                    <li className="li-amenities">
                        <img src={range} alt="icon" className="li-icon"/>
                        Electric Range Oven
                    </li>
                    <li className="li-amenities">
                        <img src={microwave} alt="icon" className="li-icon"/>
                        Microwave
                    </li>
                    <li className="li-amenities">
                        <img src={dishes} alt="icon" className="li-icon"/>
                        Dishes & Utensils
                    </li>
                   
                </ul>
                </Col>
                <Col className="col-amenities">
                <ul>
                    <li className="li-amenities">
                        <img src={beach} alt="icon" className="li-icon"/>
                        Beachfront and Ocean View
                    </li>
                    <li className="li-amenities">
                        <img src={carport} alt="icon" className="li-icon"/>
                        Free Parking & Carport 
                    </li>
                    <li className="li-amenities">
                        <img src={pool} alt="icon" className="li-icon"/>
                        Heated Swimming Pool 
                    </li>
                    <li className="li-amenities">
                        <img src={elevator} alt="icon" className="li-icon"/>
                        Elevator 
                    </li>
                    <li className="li-amenities">
                        <img src={shuffleboard} alt="icon" className="li-icon"/>
                        Shuffleboard Area
                    </li>
                    <li className="li-amenities">
                        <img src={gym} alt="icon" className="li-icon"/>
                        Fitness Center
                    </li>
                    <li className="li-amenities">
                        <img src={library} alt="icon" className="li-icon"/>
                        Library
                    </li>
                    <li className="li-amenities">
                        <img src={pooltable} alt="icon" className="li-icon"/>
                        Pool table
                    </li>
                    <li className="li-amenities">
                        <img src={hut} alt="icon" className="li-icon"/>
                        Chickee Hut
                    </li>
                    <li className="li-amenities">
                        <img src={laundry} alt="icon" className="li-icon"/>
                        Washer & Dryer (Conveniently located just outside unit. No additional laundry fees).
                    </li>
                    <li className="li-amenities">
                        <img src={security} alt="icon" className="li-icon"/>
                        24-hour Front Desk Security
                    </li>
                    <li className="li-amenities">
                        <img src={camera} alt="icon" className="li-icon"/>
                        Exterior Building Security Cameras
                    </li>
                    <button class="btn btn-light" onClick={extra_amenities}>Additional Amenities</button>
                </ul>
                </Col>
            </Row>
        </Container>
                </div>
    );
}
export default AmenitiesComponent;
