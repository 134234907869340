import './css/App.css';
import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import family from './images/family.jpg'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import axios from 'axios';
import { Calendar, momentLocalizer  } from 'react-big-calendar';
import NavbarComponent from './components/navbar';
import CondoComponent from './components/condo_carousel';
import HomeComponent from './components/home';
import OpenerComponent from './components/opener';
import FooterComponent from './components/footer';
import APIG from './js/apig';

const localizer = momentLocalizer(moment)

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          events: [],
          activeSlideIndex: 0,
        };
      }
    componentDidMount  = () => {
        axios
        .get('https://www.googleapis.com/calendar/v3/calendars/c_v156tvjfkb1vtgkgo2h2l5u438@group.calendar.google.com/events?key=AIzaSyAqKHrggEqZDxK-B4zcIGdmCjJ-DZ8bm7M&maxResults=2500')
        .then(response => {
            let events = (response.data.items).map(item => { return {id: item.id, title: "Unavailable", allDay: true, start: item.start.date, end: item.end.date} } )
            this.setState ({ events })
        })
        .catch(function(error) {
            console.log("failed to get events")
        });
    };

    render() {
        return (
            <main style={{fontFamily: 'Libre Baskerville'}}>
                <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
                <link href='https://fonts.googleapis.com/css?family=Libre Baskerville' rel='stylesheet'/>
                {/* The Nav Bar Menu*/}
                <HashRouter>
                <NavbarComponent/>
                <Routes>
                    <Route path="/" element={<HomeComponent/>} />
                    <Route path="/opener" element={<OpenerComponent/>} />
                    {/* The 3D Rotating Carousel of Condo Images*/}
                    <Route path="/condo" element={<CondoComponent/>} />
                    {/* The Grid of condo amenities and local activies*/}
                    <Route path="/booking" element={
                        <div>
                        <div className="container calendar" id="calendar">
                            <h2>Rental Calendar</h2>
                            <Calendar
                                localizer={localizer}
                                events={this.state.events}
                                views={ ['month'] }
                                components={{ toolbar:''}}
                                eventPropGetter={events => {
                                    return {
                                        style: {
                                            backgroundColor: "black"
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className="container-contact" style={{marginBottom: "10vh", fontFamily: 'Libre Baskerville'}}>
                        <form class="form-horizontal" id="emailForm" onSubmit={APIG}>
                            <label form="emailForm">
                                <h2><em>BOOK YOUR STAY</em></h2>
                            </label>
                            <div className="form-group">
                                <input type="email" className="form-control" id="emailAddress" placeholder="Email Address (name@example.com)"></input>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" id="inquiryInfo" rows="5" placeholder="Get in touch! (Please include desired dates)"></textarea>
                            </div>
                            <div className="text-right">
                                <button className="btn btn-light" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    </div>
                    } />
                    <Route path="/aboutus" element={
                        <div className="container-aboutus" id="aboutus" style={{fontFamily: 'Libre Baskerville', fontSize: '16pt', marginTop: "90px"}}>
                            <h2 style= { {textAlign: `center`}}><em>Meet Your Hosts</em></h2>
                            <h4 style={{textAlign: 'center'}}>THE TEAM AT AG ASPIRE</h4>
                            <hr style={{backgroundColor: '#cfc29b'}}/>
                            <img src={family} alt="Family" className="img-family"/>
                            <p style={{textAlign: "justify", paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px'}}> Family is everything.
                            We treasure every memory we make here in Naples and hope you get the chance to soak up the sun with the ones that mean the most to you!
                </p>
                        </div>
                    } />
                </Routes>
                <FooterComponent/>
            </HashRouter>
            </main>
        );
    }
}

export default App;