import axios from 'axios';


export default function APIG () {
    var email = (document.getElementById('emailAddress').value)
    var data= (document.getElementById('inquiryInfo').value)
    axios.post(
        'https://rckbaiwf27.execute-api.us-east-1.amazonaws.com/InquiryEmailer?email=' + email + '&body=' + data,
    )

    alert("Thank you for your inquiry, we will be in touch shortly")
}