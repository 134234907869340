import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import AGGoldLogo from '../images/AGLogoGoldandDark.png';


function NavbarComponent () {
    return (
        <Navbar collapseOnSelect fixed="top" className="nav-custom" expand="lg" style={{marginBottom: "10vh"}}>
            <Container>
                <Navbar.Brand>
                    <NavLink to="/"><img src={AGGoldLogo} alt="Logo" width="50" maxHeight="50"></img></NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <span>
                    <FontAwesomeIcon icon={faBars} color="#D8B98A" size="lg"/>
                    </span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link eventKey="1">
                    <NavLink
                            to="/condo"
                            style={{color:"white"}}>
                                Condo
                    </NavLink>
                    </Nav.Link>
                    <Nav.Link eventKey="2">
                        <NavLink
                            to="/booking"
                            style={{color:"white"}}>
                                Booking
                        </NavLink>
                    </Nav.Link>
                    <Nav.Link eventKey="3">
                        <NavLink
                            to="/aboutus"
                            style={{color:"white"}}>
                                About Us
                        </NavLink>
                    </Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarComponent;