import "../css/opener.css";
import logo from '../images/AGLogoGoldandDark.png'
import { NavLink } from 'react-router-dom';

function OpenerComponent () {
    return (
        <div className="opener">
            <br/>
            <br/>
            <h2 className="opener-header"><em>WELCOME TO PARADISE</em></h2>
            <img className="opener-logo" src={logo} alt="logo"/>
            <p className="opener-para">
            Our serene and modern Naples beachfront condo features stunning Gulf of Mexico ocean
            views in both bedrooms and heavenly sunsets from the outdoor private lanai.
            The perfect location is only a short walk from Venetian Village and just a short drive to 5th Ave South Naples.
            Newly renovated with luxury and relaxation in mind, it truly is a slice of heaven.
            </p>
            <br/>
            <hr/>
            <br/>
            <NavLink
                to="/booking">
                <button className="opener-button" style={{color: 'white'}}>
                        Book Now
                </button>
            </NavLink>
        </div>
    );
}

export default OpenerComponent;