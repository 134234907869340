import '../css/carousel.css';
import React from 'react';
//import {Carousel} from '3d-react-carousal';
import { Carousel, Container } from 'react-bootstrap'

import guest_room2 from '../images/webp/IMG_0915.webp'
import kitchen from '../images/webp/IMG_0947.webp'
import living2 from '../images/webp/IMG_0965.webp'
import master_bath from '../images/webp/IMG_1051.webp'
import master from '../images/webp/IMG_1069.webp'
import pool from '../images/webp/IMG_1273.webp'
import beach from '../images/webp/IMG_1297.webp'
import master3 from '../images/webp/IMG_1058.webp'
import living from '../images/webp/IMG_0936.webp'

import AmenitiesComponent from './amenities';


// let slides = [
//     <img  src={entry} alt="entry" className="carousel"/>,
//     <img  src={guest_room} alt="guest room" className="carousel"/>,
//     <img  src={guest_room2} alt="guest room 2"classnName="carousel"/>,
//     <img  src={guest_shower} alt="guest shower" className="carousel"/>,
//     <img  src={dining_room} alt="dining room" className="carousel"/>,
//     <img  src={kitchen} alt="dining room" className="carousel"/>,
//     <img  src={living_room} alt="living room" className="carousel"/>,
//     <img  src={patio} alt="master shower" className="carousel"/>,
//     <img  src={master_closet} alt="master closet"classnName="carousel"/>,
//     <img  src={master_vanity} alt="master shower"classnName="carousel"/>,
//     <img  src={master_shower} alt="master shower" className="carousel"/>,
//     <img  src={master_bed} alt="master bed" classnName="carousel"/>,
//     <img  src={master_bed2} alt="master bed2" className="carousel"/>,
//     ];


// function oldCondoComponent (){
//     return (
//         <div className="slide-container" id="condo">
//             <br/>
//             <Carousel slides={slides} autoplay={false} arrows={true}/>
//         </div>
//     )
// }

function CondoComponent (){
    return (
        <div style={{marginTop: '20px'}}>
        <Carousel className="carousel" id='condo'>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={guest_room2} alt="guest room 2" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                    <img  src={living} alt="guest shower" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={living2} alt="dining room" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={kitchen} alt="kitchen" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={master_bath} alt="kitchen2" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <img  src={master3} alt="living room" className="img-carousel"/>
            </Carousel.Item>
            <Carousel.Item>
                <img  src={master} alt="living room2" className="img-carousel"/>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={pool} alt="master shower" className="img-carousel"/>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container className="carousel-container">
                <img  src={beach} alt="master closet" className="img-carousel"/>
                </Container>
            </Carousel.Item>
        </Carousel>
        <AmenitiesComponent/>
        </div>
    )
}
export default CondoComponent;