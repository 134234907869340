import '../css/footer.css'
import location from '../images/location_icon.png'
import phone from "../images/phone_icon.png"
import email from '../images/email_icon.png'
import {Container, Row, Col} from 'react-bootstrap'

function FooterComponent () {
    return (
        <Container className="footer-container">
            <Row>
                <Col sm={2} lg={5} className="footer-img"></Col>
                <Col className='footer-col'>
                    <h2 className="footer-header"><em>CONTACT US</em></h2>
                    <Row className="footer-icon-row">
                        <Col xs={3} md={1}><img className="footer-icon" src={location} alt="location"></img></Col>
                        <Col xs={8} md={11}><a className="footer-link" href="https://www.google.com/maps/place/3443+Gulf+Shore+Blvd+N,+Naples,+FL+34103/@26.1880088,-81.8170552,17z/data=!3m1!4b1!4m5!3m4!1s0x88db1e29b871fe11:0xa6a9c2593ddccb11!8m2!3d26.1880088!4d-81.8148612">3443 Gulf Shore Blvd N, Naples, FL 34103</a></Col>
                    </Row>
                    <Row className="footer-icon-row">
                        <Col xs={3} md={1}><img className="footer-icon" src={phone} alt="phone"></img> </Col>
                        <Col xs={8} md={11}><a className="footer-link" href="tel:2176225252">(217) 652-5252</a></Col>
                    </Row>
                    <Row className="footer-icon-row">
                        <Col xs={3} md={1}><img className="footer-icon" src={email} alt="email"></img></Col>
                        <Col xs={8} md={11}><a className="footer-link" href="mailto:info@agaspire.com" style={{marginLeft: '3px'}}>info@agaspire.com</a></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="container text-center">
                    <span style={{color: "grey", fontSize: "10px"}}>AG Aspire LLC 2021</span>
                </div>
            </Row>
        </Container>
    );
}

export default FooterComponent;