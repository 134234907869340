import beach from '../images/condo/beach.png'
import OpenerComponent from './opener';

function HomeComponent () {
    return (
        <div className="header">
            <img className='img-header' src={beach} alt="beach"/>
        <OpenerComponent/>
        </div>
    );
}

export default HomeComponent;